import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Button } from "../components/Button"
const Projects = () => (
  <Layout page="projects" navBarColor="#181818">
    <SEO title="Projekte" />
    <ProjectWrapper>
      <ProjectsHeader>
        <Title>Aktuelle Projekte</Title>
        <Subtitle>Diese Projekte sind aktuell in der Pipeline</Subtitle>
      </ProjectsHeader>
      <ProjectsContainer>
        <ProjectCard>
          <ProjectTitle>Aktuell keine Projekte</ProjectTitle>
          <ProjectDescription></ProjectDescription>
          <Button to="/blog">Abgeschlossene Projekte</Button>
        </ProjectCard>
      </ProjectsContainer>
    </ProjectWrapper>
  </Layout>
)

export default Projects

const ProjectsHeader = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #121212;
  color: #fff;
  /*  @media screen and (max-width: 767px) {
    padding: 5rem 1rem;
  }
  @media screen and (max-width: 500px) {
    padding: 4rem 2rem;
  } */
`
const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: clamp(1.5rem, 5vw, 2rem);
`
const Subtitle = styled.div`
  text-align: center;
`
const ProjectWrapper = styled.div``
const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: center;
  padding: 5rem calc((100vw - 800px) / 2);

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
const ProjectCard = styled.div`
  line-height: 2;
  width: 100%;
  /* height: 500px; */
  position: relative;
  border-radius: 10px;
  transition: 0.2 ease;
  padding: 1rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));

  overflow: hidden;
  text-decoration: none;
`
const ProjectTitle = styled.div``

const ProjectDescription = styled.div``
